"use client";
//? Library Imports ------------------------------------------------------------>
import { useDispatch } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { Col, Form, Input, Button, message, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./SignupForm.scss";

//? Modules -------------------------------------------------------------------->
import { getCurrentDateTime } from "../../../functions/HelperFunctions/index";
import Cookies from "js-cookie";
//? Redux Reducers ------------------------------------------------------------->
import usePath from "@/hooks/usePath/usePath";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";
import ReCAPTCHA from "react-google-recaptcha";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

//? Functions ------------------------------------------------------------------>

export default function SignupForm({ setShowLoginModal, mobileScreen }) {
  //? Hooks ---------------------------------------------------------------------->
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  // const captchaRef = useRef();
  // const [captchaError, setCaptchaError] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState(null);
  // const captchaKey = process.env.NEXT_PUBLIC_CAPTCHA_KEY;

  // const handleCaptchaChange = (value) => {
  //   if (value) {
  //     setCaptchaError(false);
  //   } else {
  //     setCaptchaError(true);
  //   }
  //   // This callback will be called when the user completes the reCAPTCHA challenge
  //   setCaptchaValue(value);
  // };

  const onSignUp = async (data) => {
    // if (captchaValue) {
    let currentData = getCurrentDateTime();

    if (data.password !== data.confirm_password) {
      return message.error("Password does not match");
    }

    let body = {
      full_name: data?.full_name,
      email: data?.email,
      phone: data?.phone,
      password: data?.password,
      confirm_password: data?.confirm_password,
    };

    const payload = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    const SignUp = await fetch(
      process.env.NEXT_PUBLIC_BACKEND + `/storefront/signup?storefront=23`,
      payload
    );

    const SignupResponse = await SignUp.json();

    if (!SignUp.ok) {
      if (SignupResponse.hasOwnProperty("email")) {
        message.error("Email already exists");
      } else {
        message.error(SignupResponse.detail);
        throw new Error("SignUp Api Failed!");
      }
    } else {
      if (window.Moengage && window.Moengage.track_event) {
        window.Moengage.track_event("Customer Registered", {
          "Event Received Time": currentData,
          Currency: cookiesCurrency ? cookiesCurrency : "PKR",
          Email: data?.email,
          Source: "Website",
          // "First Session": true
        });
        window.Moengage.add_email(data?.email);
        Moengage.add_unique_user_id(data?.email);
        window.Moengage.add_first_name(data?.full_name);
        window.Moengage.add_last_name(data?.full_name);
        window.Moengage.add_user_name(data?.full_name);
        data?.phone && window.Moengage.add_mobile(data?.phone);
      }

      message.success("Signup Successful");
      dispatch(loginReducer(SignupResponse));
      customerLogin(SignupResponse);
      setShowLoginModal(false);

      if (path !== "/cart/checkout") {
        router.push("/");
      }
    }
    // } else {
    //   setCaptchaError(true);
    // }
  };

  const onSignUpFailed = (errorInfo) => {
    customerLogout();

    message.error(errorInfo);
  };

  const validateFullName = (name) => {
    const nameRegex = /^[a-zA-Z\s-]+$/;
    return nameRegex.test(name) && name.trim().length >= 3 && name.trim().length <= 50;
  };

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^[0-9]{10,15}$/;
    return mobileRegex.test(mobile);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  return (
    <Col className={mobileScreen ? "signup-form-mobile" : "signup-form"}>
      <Form
        name="Login-Form"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 533 }}
        initialValues={{
          remember: true,
        }}
        onFinish={onSignUp}
        onFinishFailed={onSignUpFailed}
      >
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Full Name</Text>
        </Col>
        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please Enter Your Full Name",
            },
            {
              validator: (_, value) => {
                if (!value || validateFullName(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Full Name must be between 3-50 characters and contain only letters, spaces, or hyphens."));
              },
            },
          ]}
        >
          <Input style={{ height: "41px" }} maxLength={50} placeholder="Full Name"
            onChange={(e) => {
              const inputValue = e.target.value;
              const filteredValue = inputValue.replace(/[^a-zA-Z\s\-]/g, '');
              e.target.value = filteredValue;
            }}
          />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Email</Text>
        </Col>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
            {
              validator: (_, value) => {
                if (!value || validateEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please enter a valid email address."));
              },
            },
          ]}
        >
          <Input style={{ height: "41px" }} placeholder="Email" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Mobile Number</Text>
        </Col>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please Enter a Valid Mobile Number",
            },
            {
              validator: (_, value) => {
                if (!value || validateMobileNumber(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Mobile number must be between 10-15 digits and contain only numbers."));
              },
            },
          ]}
        >
          <Input
            className="no-spinners"
            style={{ height: "41px" }}
            placeholder="923456789"
            type="number"
          />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Password</Text>
        </Col>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter Your Password",
            },
            {
              min: 6,
              message: "Too Short",
            },
            {
              max: 12,
              message: "Too Long maximum 12 characters"
            }
          ]}
        >
          <Input.Password style={{ height: "41px" }} placeholder="**********" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-labek">Confirm Password</Text>
        </Col>
        <Form.Item
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Kindly Confirm Your Password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password style={{ height: "41px" }} placeholder="**********" />
        </Form.Item>
        {/* <div className="sign-up-captcha-div">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={captchaKey} // Replace with your reCAPTCHA Site Key
            onChange={handleCaptchaChange}
          />
          {captchaError && (
            <div className="sign-up-captcha-error">Captcha is required.</div>
          )}
        </div> */}
        <Form.Item>
          <Button htmlType="submit" className="signup-btn"
            style={{
              backgroundColor: "#D62314"
            }}
          >
            Signup
          </Button>
        </Form.Item>
        {mobileScreen && (
          <Col
            span={24}
            align="middle"
            style={{ marginTop: "20px", marginBottom: "10px" }}
          >
            <Text
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
              }}
            >
              Already have an account? &nbsp;
              <Text
                style={{
                  // color: "#FF3F6C",
                  color: "var(--background-color)",
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginLeft: "-3px",
                }}
                onClick={() => {
                  setShowLoginModal(true);
                }}
              >
                Login
              </Text>
            </Text>
          </Col>
        )}
      </Form>
    </Col>
  );
}
