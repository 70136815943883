import { useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Button, Input, Select, Form, message, Image, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";
import { loginReducer } from "@/redux/Slices/accountSlice";
import usePath from "@/hooks/usePath/usePath";
import DealAndDiscount from "../../assets/images/dealdiscount.png";
import AuthenticPro from "../../assets/images/AuthenticPro.png";
import AlfaMallLogo from "../../assets/images/AlfaMall.png";
import CustomerSupport from "../../assets/images/customersupport.png";
import Logo from "../../assets/images/logo.png";
import "./LoginSignUp.scss";
import { getCurrentDateTime } from "@/functions/HelperFunctions";
import { useRouter, useSearchParams } from "next/navigation";

const { Option } = Select;

const LoginSignup = (props) => {
  const searchParams = useSearchParams();
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  const cookiesCurrency = Cookies.get("defaultCurrencyName");

  const [isCheckingParams, setIsCheckingParams] = useState(true); // Add this state

  useEffect(() => {
    const deviceId = searchParams.get("device_id");
    const type = searchParams.get("type");

    if (deviceId && type === "mobile-app") {
      router.push(
        `${process.env.NEXT_PUBLIC_BACKEND}/payment/baf_sso?device_id=${deviceId}&type=${type}`
      );
    } else {
      setIsCheckingParams(false);
    }
  }, [searchParams, router]);

  if (isCheckingParams) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  // Toggle between Sign In and Sign Up
  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };
  const handleGuestUser = () => {
    Cookies.set("guest-user", true, {
      expires: 365,
    });
    // window.location.reload();
    if (props?.setShowLoginModal) {
      props?.setShowLoginModal(false);
    } else {
      setTimeout(() => {
        router.push("/");
      }, 1000);
    }
  };
  // Handle Login Functionality
  const onLogin = async (values) => {
    let currentDate = getCurrentDateTime();
    setLoading(true);

    try {
      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/storefront/signin?storefront=23`,
        payload
      );

      if (!res.ok) {
        setLoading(false);
        throw new Error("Login API Failed!");
      } else {
        if (props?.setShowLoginModal) {
          props?.setShowLoginModal(false);
        }
      }

      const LoginResponse = await res.json();

      customerLogin(LoginResponse);
      dispatch(loginReducer(LoginResponse));
      setLoading(false);

      message.success("Successfully logged in!");
      Cookies.set("guest-user", true, {
        expires: 365,
      });
      // Track login events if needed
      if (window.Moengage && window.Moengage.track_event) {
        window.Moengage.add_email(values?.email);
        window.Moengage.add_unique_user_id(values?.email);
        window.Moengage.add_first_name(LoginResponse?.full_name);
        window.Moengage.add_last_name(LoginResponse?.full_name);
        window.Moengage.add_user_name(LoginResponse?.full_name);
        LoginResponse?.phone &&
          window.Moengage.add_mobile(LoginResponse?.phone);
        window.Moengage.track_event("Customer Logged In", {
          "Event Received Time": currentDate,
          Email: values?.email,
          Phone: LoginResponse?.phone,
          FirstName: LoginResponse?.full_name,
          LastName: LoginResponse?.full_name,
          "Customer ID": LoginResponse?.id,
          Source: "Website",
          Currency: cookiesCurrency ? cookiesCurrency : "PKR",
        });
      }

      // Handle login redirect
      if (path !== "/cart/checkout") {
        router.push("/");
      }

      return LoginResponse;
    } catch (error) {
      message.error("Invalid Email or Password");
      console.error("Unable to Login:", error.message);
      setLoading(false);
    }
  };

  // Handle Signup Functionality
  const onSignUp = async (data) => {
    let currentData = getCurrentDateTime();
    if (data.password !== data.confirm_password) {
      return message.error("Passwords do not match");
    }

    let body = {
      full_name: data.full_name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      confirm_password: data.confirm_password,
    };

    const payload = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };

    const SignUp = await fetch(
      process.env.NEXT_PUBLIC_BACKEND + `/storefront/signup?storefront=23`,
      payload
    );

    const SignupResponse = await SignUp.json();

    if (!SignUp.ok) {
      if (SignupResponse.hasOwnProperty("email")) {
        message.error("Email already exists");
      } else {
        message.error(SignupResponse.detail);
        throw new Error("Signup API Failed!");
      }
    } else {
      if (props?.setShowLoginModal) {
        props?.setShowLoginModal(false);
      }
      message.success("Signup Successful");
      dispatch(loginReducer(SignupResponse));
      customerLogin(SignupResponse);
      setIsSignUp(false); // Switch back to login form after successful signup
      Cookies.set("guest-user", true, {
        expires: 365,
      });
      if (path !== "/cart/checkout") {
        router.push("/");
      }
    }
  };

  const onLoginFailed = (errorInfo) => {
    customerLogout();
    message.error("Login Failed");
  };

  const onSignUpFailed = (errorInfo) => {
    message.error("Signup Failed");
  };

  return (
    <div className={`container ${isSignUp ? "active" : ""}`} id="container">
      {/* Sign Up Form */}
      <div className={`form-container sign-up`}>
        <Form
          name="Signup-Form"
          layout="vertical"
          onFinish={onSignUp}
          onFinishFailed={onSignUpFailed}
        >
          <h1 className="font30Bold textCenter marginBottom0">
            Create Account
          </h1>
          <br />
          <Form.Item
            name="full_name"
            rules={[
              {
                required: true,
                message: "Please enter your full name",
              },
              {
                max: 25,
                message: "Full name cannot be more than 25 characters",
              },
            ]}
          >
            <Input
              placeholder="Full Name"
              maxLength={25}
              onKeyPress={(e) => {
                const regex = /^[A-Za-z\s]*$/;
                if (!regex.test(e.key)) {
                  e.preventDefault(); // Prevents input if the character does not match the regex
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
              () => ({
                validator(_, value) {
                  // Allow only letters, numbers, @, ., -, _, and +, and restrict anything else
                  const emailRegex = /^[a-zA-Z0-9@._+-]+$/;

                  if (value && !emailRegex.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Email cannot contain special characters except '@', '.', '-', '_', and '+'"
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Email"
              maxLength={50}
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /[^a-zA-Z0-9@._+-]/g,
                  ""
                );
              }}
            />
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please enter your phone number" },
              { max: 160, message: "Phone number cannot exceed 16 digits" },
              () => ({
                validator(_, value) {
                  const phoneRegex = /^[0-9]+$/;
                  if (value && value.length > 20) {
                    return Promise.reject(
                      new Error("Phone number cannot exceed 16 digits")
                    );
                  }
                  if (value && !phoneRegex.test(value)) {
                    return Promise.reject(
                      new Error("Phone number must contain only numbers")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Phone Number"
              type="text"
              maxLength={16}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
          </Form.Item>

          <Form.Item
            className="password"
            name="password"
            rules={[
              { required: true, message: "Please enter your password" },
              {
                min: 6,
                message: "Password must be at least 6 characters long",
              },
            ]}
          >
            <Input.Password placeholder="Password" maxLength={20} />
          </Form.Item>

          <Form.Item
            className="password"
            name="confirm_password"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" maxLength={20} />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Signup
          </Button>
        </Form>
      </div>

      {/* Sign In Form */}
      <div className={`form-container sign-in alfa-sign-in `}>
        <Form
          name="Login-Form"
          style={{ display: "grid" }}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onLogin}
          // onFinishFailed={onLoginFailed}
          className="login-form-container"
        >
          <Image
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className="paddingTop10 marginTop20"
            preview={false}
            src={AlfaMallLogo.src}
            width={170}
            height={70}
          />
          <h1
            className="textCenter font30Bold"
            style={{ marginBottom: "-10px" }}
          >
            Sign In
          </h1>
          <br />
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
              () => ({
                validator(_, value) {
                  // Allow only letters, numbers, @, ., -, _, and +, and restrict anything else
                  const emailRegex = /^[a-zA-Z0-9@._+-]+$/;

                  if (value && !emailRegex.test(value)) {
                    return Promise.reject(
                      new Error(
                        "Email can only contain letters, numbers, '@', '.', '-', '_', and '+'"
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Email"
              onInput={(e) => {
                // Remove any character that is not allowed
                e.target.value = e.target.value.replace(
                  /[^a-zA-Z0-9@._+-]/g,
                  ""
                );
              }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Link href="/account/forgotpassword">Forgot Password?</Link>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {!loading ? (
                "Login"
              ) : (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "white" }}
                      spin
                    />
                  }
                />
              )}
            </Button>
          </Form.Item>
          <p className="or"> Login with </p>
          <a
            className="login-with-alfa"
            href={`${process.env.NEXT_PUBLIC_BACKEND}/payment/baf_sso`}
          >
            Alfa/Internet Banking
          </a>
          <p style={{ textAlign: "center" }}>
            Continue as
            <a
              className="login-with-alfa"
              onClick={handleGuestUser}
              type="primary"
              htmlType="submit"
              // style={{ width: "100%" }}
            >
              Guest User
            </a>
            {/* <span
              onClick={handleGuestUser}
              style={{
                color: "#A01D18",
                cursor: "pointer",
                fontFamily: "ZemestroStd-Regular",
                fontSize: "14px",
              }}
            >
              &nbsp;Guest User
            </span> */}
          </p>
        </Form>
      </div>

      {/* Toggle Container */}
      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1 className="font30Bold">Welcome to AlfaMall!</h1>
            <p>Fill up your details to get started.</p>
            <Button onClick={toggleForm}>Sign In</Button>
          </div>

          <div className="toggle-panel toggle-right">
            {/* <Image preview={false} src={Logo.src} height="auto" alt="Logo" /> */}
            <br />
            <h1
              style={{ lineHeight: "35px", width: "90%" }}
              className="textCenter font30Bold"
            >
              Pakistan's{" "}
              <span
                className="font30Bold"
                // style={{ position: "relative", display: "inline-block" }}
              >
                1<sup className="font20">st</sup>
              </span>{" "}
              BNPL Marketplace
            </h1>

            <br />
            <div style={{ display: "flex", gap: "15px" }}>
              <Image
                className="icon-images"
                preview={false}
                src={DealAndDiscount.src}
                width="auto"
                height="95%"
                alt="Deal Discount"
              />
              <Image
                className="icon-images"
                preview={false}
                src={AuthenticPro.src}
                width="auto"
                height="95%"
                alt="Authentic Pro"
              />
              <Image
                className="icon-images"
                preview={false}
                src={CustomerSupport.src}
                width="auto"
                height="95%"
                alt="Customer Support"
              />
            </div>
            <br />
            <Button onClick={toggleForm}>Sign Up</Button>
            <p>Let's get your profile setup</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
