"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { useState } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useRouter } from "next/navigation";
import { Button, Col, Form, Input, Row, message, Typography } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getCurrentDateTime } from "../../../functions/HelperFunctions/index";

//? Assets --------------------------------------------------------------------->
import "./LoginForm.scss";

//? Redux Reducers ------------------------------------------------------------->
import usePath from "@/hooks/usePath/usePath";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function LoginForm({ setShowLoginModal }) {
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  //? Hooks ---------------------------------------------------------------------->
  const [loading, setloading] = useState(false);
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();

  //? Functions ------------------------------------------------------------------>
  const onLogin = async (values) => {
    let currentDate = getCurrentDateTime();
    setloading(true);

    try {
      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/storefront/signin?storefront=23`,
        payload
      );

      if (!res.ok) {
        setloading(false);
        throw new Error("Login Api Failed!");
      }

      const LoginResponse = await res.json();

      customerLogin(LoginResponse);
      dispatch(loginReducer(LoginResponse));
      setShowLoginModal(false);

      if (res.ok) {
        message.success("Successfully logged in!");

        if (window.Moengage && window.Moengage.track_event) {
          window.Moengage.add_email(values?.email);
          Moengage.add_unique_user_id(values?.email);
          window.Moengage.add_first_name(LoginResponse?.full_name);
          window.Moengage.add_last_name(LoginResponse?.full_name);
          window.Moengage.add_user_name(LoginResponse?.full_name);
          LoginResponse?.phone &&
            window.Moengage.add_mobile(LoginResponse?.phone);
          window.Moengage.track_event("Customer Logged In", {
            "Event Received Time": currentDate,
            Email: values?.email,
            Phone: LoginResponse?.phone,
            FirstName: LoginResponse?.full_name,
            LastName: LoginResponse?.full_name,
            "Customer ID": LoginResponse?.id,
            Source: "Website",
            Currency: cookiesCurrency ? cookiesCurrency : "PKR",
          });
        }
        if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
          if (typeof window !== "undefined" && window?.dataLayer) {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "login",
              user_id: LoginResponse?.id,
            });
          }
        }
      }

      if (path !== "/cart/checkout") {
        router.push("/");
      }

      return LoginResponse;
    } catch (error) {
      message.error("Invalid Email or Password");
      console.error("Unable to Login:", error.message);
      return null;
    }
  };

  const onLoginFailed = (errorInfo) => {
    customerLogout();
    message.error(errorInfo);
  };

  return (
    <Col className="login-form marginAuto">
      <Form
        name="Login-Form"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 533, margin: "auto" }}
        initialValues={{
          remember: true,
        }}
        onFinish={onLogin}
        onFinishFailed={onLoginFailed}
      >
        <Col style={{ marginBottom: "7px" }}>
          <Text className="form-label">Email</Text>
        </Col>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please Enter a Valid Email",
            },
          ]}
        >
          <Input style={{ height: "41px" }} placeholder="Email" />
        </Form.Item>
        <Col style={{ marginBottom: "7px" }}>
          <Row justify={"space-between"}>
            <Text className="form-label">Password</Text>
            <Link href="/account/forgotpassword">
              <Text style={{ fontSize: "14px", lineHeight: "21px" }}>
                Forgot Your Password?
              </Text>
            </Link>
          </Row>
        </Col>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please Enter your Password",
            },
          ]}
        >
          <Input.Password style={{ height: "41px" }} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-btn login-button-spinner"
            style={{
              backgroundColor: "#D62314"
            }}
          >
            {!loading ? (
              "Login"
            ) : (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                      color: "white",
                    }}
                    spin
                  />
                }
              />
            )}
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
}
